import {createSlice} from '@reduxjs/toolkit';

export const alertMessage = createSlice({
    name:'alert',
    initialState:{message:"",status:""},
    reducers:{
        setAlert:(state,action)=>{
            return{...state,
                    message:action.payload.message,
                    status:action.payload.status}
        }
    }
});

export const {setAlert} = alertMessage.actions;
export default alertMessage.reducer;