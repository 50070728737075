import {
  Card,
  CardContent,
  CardActions,
} from "@cambridgeassessment/cambridge-ui";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getPassRateData } from "../../../services/muleSoftServices";
import { setTabNumber } from "../../../redux/slice/sideNavigation";
import { useDispatch, useSelector } from "react-redux";
import "./schoolInsightItem.css";
import { setPassRateData } from "../../../redux/slice/passRate";
import { isEmpty } from "lodash";
import { setAlert } from "../../../redux/slice/GlobalMessage";
import { constants } from "../../../constants";

const SchoolInsightItem = ({ info,exams }) => {
  const navigate = useNavigate(),
        dispatch = useDispatch();

  const handleViewReport = () => {
    dispatch(setTabNumber({reportTabNumber:1,managementTabNumber:-1}));
    navigate("/pass-rates", { state: { level: info?.value, series:info?.month } });
  };

  return (
    <>
      <Card>
        <CardContent className="pb-0">
          <div className="d-flex flex-column align-items-start">
            <p className="schInsightYearCls m-0 p-0">{info.month}</p>
            <p className="schInsightLevelCls">{info.title}</p>
          </div>
          <hr className="m-0 p-0 border border-secondary border-1"></hr>
          <div className="container chartCls">
            {exams.length>0 && exams?.map((d) => (
              <div className="row chartRowCls">
                <div className="col-4 px-0 courseCls">
                  <span className="d-inline-block text-truncate w-100" title={d.sylName} style={{ maxWidth: "120px" }}>{d.sylName}</span>
                </div>
                <div className="col-8 px-0 courseCls">
                  <div className="progress p_bar_rad barCls">
                    <div style={{ height: "100%", width: d.passRate<10 && d.passRate>0? "auto":`${d.passRate}%` }}
                         className="progress-bar head_bg_blue bar_text"
                         role="progressbar">
                          <span className="mx-1">{Number(d.passRate).toFixed(1)}%</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
        <CardActions className="d-flex justify-content-start">
          {exams.length>0 && <a className="viewReport" onClick={handleViewReport}>View full report</a>}
        </CardActions>
      </Card>
    </>
  );
};

export default SchoolInsightItem;
