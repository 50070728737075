import { Button, Card, Notification, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@cambridgeassessment/cambridge-ui";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GrpDropDown } from "../../common/Dropdown";
import Spinner from "../../common/Spinner";
import { constants } from "../../constants";
import "./GradeDistribution.css";
import { getGradeDistributionList } from "../../services/muleSoftServices";
import { borderBottom } from "@mui/system";
import { useReactToPrint } from "react-to-print";
import labels from "../../common/labels";
import { setRecentSelectedSeries } from "../../redux/slice/passRate";

const GradeDistribution = () =>{
    const savedResp = useSelector((state) => state.pass), 
        dispatch = useDispatch(), 
        initialState = {
        seriesSelectedValue:!isEmpty(savedResp.selectedSeries)?savedResp.selectedSeries:{},
        qualificationSelectedValue:{},
        spin:true
    },
    [initialData,setInitialData] = useState(initialState),
    [levels,setLevels] = useState([]),   
    spinnerRef = useRef(""),
    initialized = useRef(false),
    seriesList = useRef([]),
    gradeRef = useRef(null),
    userInfo = useSelector((state) => state.userInfo);

    useEffect(()=>{
        let firstSeries;
        const {seriesSelectedValue} = initialData;
        if(!isEmpty(savedResp.seriesLabels) && !initialized.current && !isEmpty(savedResp.seriesDropList)){
            initialized.current = true;
            seriesList.current = savedResp.seriesDropList;
            firstSeries = savedResp.seriesDropList?.filter(s=>s.label === savedResp.seriesLabels[0]);
            setGradeParams(!isEmpty(seriesSelectedValue)?initialData.seriesSelectedValue?.label:firstSeries[0].label);
            if(isEmpty(seriesSelectedValue)){
                setInitialData((previousState)=>{
                    return {...previousState,seriesSelectedValue:firstSeries[0]}
                })
            }
        }       
    },[])

    const setGradeParams = async(series) =>{
        const {qualification} = constants.dropDownList;
        let totLevels = [];
        for(let i=0;i<qualification.length;i++){
          let obj = await getGradeData(series,qualification[i].label);
          if(!isEmpty(obj)){
            totLevels.push(obj);
          }
        }       
        setLevels(totLevels);
        spinnerRef.current?.spinnerOpen();
        setInitialData((previousState)=>{
            return {...previousState,spin:false}
        })
    }
    
    const selectedOption = (key, li) => {
        dispatch(setRecentSelectedSeries({userSelectedSeries:li}));
        setGradeParams(li.label);
        setInitialData((previousState)=>{
            return {...previousState,spin:true,[key]: li}
        })
    }

    useEffect(()=>{
        spinnerRef.current?.spinnerOpen();
      },[initialData.spin])

    const getGradeData = async(series,qual) =>{
        const {totalLoggedInData,roleFromSAP,orgs,roleLevel,uid} = userInfo,
              {qualification} = constants.dropDownList,
              {gradeDistribution} = constants.sideNav.roleBaseApiKeys,
              params = {token:totalLoggedInData.access_token,
                        centreId:orgs[0]?.sourceSystems[0]?.orgId,
                        series:series,
                        quals:qual,
                        bpId:orgs[0]?.bpid,
                        roleName:roleFromSAP,
                        roleLevel:gradeDistribution[roleLevel]},
        gradeResp = await getGradeDistributionList(params);
        if(!isEmpty(gradeResp?.data)){
            let levelData = gradeResp.data[qual];
            if(levelData?.length>=0){
                let obj={},eachLevelTotRows=[];
                levelData.forEach((l,i)=>{
                    let gradeHeaders=[],gradeValues=[];
                    for(let key in l.grades){
                        gradeHeaders.push(key);
                        gradeValues.push(l.grades[key]);
                    }
                    eachLevelTotRows.push({syllabus:l.syllabus+" - "+l.syllabusName,
                                            examsCount:l.totalCount,
                                            gradeVals:gradeValues,
                                            colC:l.passrateC,
                                            colE:l.passrate})                        
                    obj={levelName:qual,
                            examHead:'Exams',
                            gradeHeads:gradeHeaders,
                            geC:<>&ge;C</>,
                            geE:<>&ge;E</>}
                });
                obj.totRowsInLevel = eachLevelTotRows;
                return obj; 
            }
        }               
    }

    const downloadPdf = useReactToPrint({
        bodyClass:"m-2 p-2",
        documentTitle:'grade_Distribution',
        content: () => gradeRef.current
      });

    return (
        <>
            {initialData.spin?<Spinner ref={spinnerRef} />:
            <div ref={gradeRef}>
            <div className="row">
                <div className="col-12 row">
                    <div className="txt">
                        <span className="lbl dash_lbl text-uppercase">grade distribution</span>
                    </div>
                    <div className="sub_text dash_sub_text mt-2">
                        View grade distribution for syllabuses you teach. {labels.TEACHER_SUB_HEADER2}{" "}
                        <a href={labels.TEACHER_SUB_HEAD_LINK} target="_blank" className="link">
                            {labels.TEACHER_SUB_HEADER3}
                        </a>.
                    </div>
                </div>
                <div className={`col-12 row ${levels.length>0?"d_contents":''}`}>
                    <div className="col-lg-3 col-md-12 col-sm-12 text-start">
                        <GrpDropDown class="text-start"
                            onChange={selectedOption}
                            selectedValue={initialData.seriesSelectedValue.label}
                            userSelectedDropdown='seriesSelectedValue'
                            label={constants.passRate.dropDownLabels.series} 
                            list={seriesList.current}
                            option='label' value='label' />
                    </div>
                    <div className={`col-lg-9 col-md-12 col-sm-12 mt-3 ${userInfo.smallScreen?"text-start":"text-end p-0"}`}>
                        {levels.length>0 && <Button variant="outlined" onClick={downloadPdf}>{constants.dashboard.downloadIconBlue} Download (pdf)</Button>}
                    </div>
                </div>                
                {levels.length>0 ? levels.map((l,i)=>{                   
                    return(
                            <TableContainer className="mt-4 table_bg col-12" key={i}>
                                <Table>
                                    <TableHead className="t_border_none">
                                        <TableRow>
                                            <TableCell>{l?.levelName}</TableCell>
                                            <TableCell className="text-center">{l?.examHead}</TableCell>
                                            {l?.gradeHeads?.length>0 && l?.gradeHeads.map((g,j)=>{
                                                return(<TableCell key={j} className="text-center">{g}</TableCell>)
                                            })}
                                            <TableCell className="text-center head_bg_yellow">{l?.geC}</TableCell>
                                            <TableCell className="text-center head_bg_blue">{l?.geE}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {l?.totRowsInLevel?.length>0 && l?.totRowsInLevel.map((t,k)=>{
                                        return(<TableRow key={k} className="t_border_none">
                                            <TableCell className="w-auto">{t?.syllabus}</TableCell>
                                            <TableCell className="w_grade text-center">{t?.examsCount}</TableCell>
                                            {t?.gradeVals?.length>0 && t?.gradeVals.map((g,j)=>{
                                                return(<TableCell key={j} className="w_grade text-center">{g}</TableCell>)
                                            })}
                                            <TableCell className="m_width text-center val_bg_yellow">{t?.colC.toFixed(1)}%</TableCell>
                                            <TableCell className="m_width text-center val_bg_blue">{t?.colE.toFixed(1)}%</TableCell>
                                        </TableRow>)
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                }):
                <Notification variant="warning" className="mt-4" style={{marginLeft:"12px"}}>
                <p className="text-start mb-0">No grade information found.</p>                
              </Notification>}                
            </div>
            </div>
            }
        </> 
    )
}
export default GradeDistribution;