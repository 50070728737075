import { Card } from '@cambridgeassessment/cambridge-ui';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { constants } from '../../constants';
import './NewUser.css';
import {setnewUser} from '../../redux/slice/newUser';
import {useDispatch,useSelector} from 'react-redux';
import { termsAccepted } from '../../services/muleSoftServices';

const NewUser = () =>{
    const [btnDisable,setBtnDisable] = useState(true),
          navigate = useNavigate(),
          dispatch = useDispatch(),
          supportRole = useRef(false),
          userInfo = useSelector(state => state.userInfo),
          firstTimeOrUpdatedTermUser = useSelector((state) => state.new);

    const openTerms = () =>{
        let myWindow = window.open("", "response", "resizable=yes");
        myWindow.document.write(firstTimeOrUpdatedTermUser.tAndC);
    }

    const termsAcceptance = async() =>{
        const {totalLoggedInData,roleFromSAP,uid} = userInfo;
        setBtnDisable(!btnDisable);
        let params = {
            token:totalLoggedInData.access_token,
            roleName:roleFromSAP,
            data:{
                "userId": uid,
                "agreementStatus": "Agree"
            }
        }
        const res = await termsAccepted(params);
        console.log(res);
    }

    useEffect(()=>{       
        if(userInfo.roleLevel === "support"){
            supportRole.current = true;
            navigate('/support-admin');
        }
    },[])
    if(!supportRole.current){    
        return(<div className='ml-35'>
                <h1 className='lbl new_user_lbl m-0 text-start'>{constants.newUser.header}</h1>
                {userInfo.roleLevel !== "state" && userInfo.roleLevel !== "district" && 
                    <h2 className='sub_text new_fs pt-1'>{userInfo?.orgs[0]?.name} - {userInfo.orgs[0]?.sourceSystems[0]?.orgId}</h2>}
                {userInfo.roleLevel === "state" && <h2 className='sub_text new_fs  pt-1'>{userInfo.stateName}</h2>}
                {userInfo.roleLevel === "district" && <h2 className='sub_text new_fs pt-1'>{userInfo.districtName} - {userInfo.stateName}</h2>}
                <Card className='card_body'>
                        <div className='text-start card-head'>{constants.newUser.sec1}</div>
                            <hr/><p className='text-start ml-15 card-body-text'>
                                    By using this application, you agree to the {" "}
                                    <a href='#' onClick={openTerms} className='link'>Terms of service</a> and {" "}
                                    <a target="_blank" href='https://www.cambridge.org/legal/privacy' className='link'>Privacy policy</a>
                                </p>
                                <div className='text-start ml-15 card-body-text'>
                                    <input id='termsAcceptance' type="checkbox" onClick={termsAcceptance}/><span className='m-2'>I agree to the terms and conditions</span>
                                </div>                        
                            <hr/>
                        <div className='text-start ml-15 mb-3'>
                            <button type='button' disabled={btnDisable} 
                                    onClick={()=>{navigate('/dashboard');dispatch(setnewUser({newUser:false}))}} 
                                    className='btn btn-primary'>
                                        {constants.newUser.btn}
                            </button>
                        </div>
                </Card>
              </div>
            )
    }
}
export default NewUser;