import {createSlice} from '@reduxjs/toolkit';

export const studentReport = createSlice({
    name:'studentReport',
    initialState:{dataRows:[],allReportData:{}},
    reducers:{
        setStudentReport:(state,action)=>{
            return {...state,
                    dataRows:action.payload.rows}
        },
        setTotalReportData:(state,action)=>{
            return {...state,
                    allReportData:action.payload.totalReportData}
        }
    }
});

export const {setStudentReport,setTotalReportData} = studentReport.actions;
export default studentReport.reducer;