import { constants } from "../../../constants";
import "./schoolInsight.css";
import SchoolInsightItem from "./schoolInsightItem";
import {useSelector} from 'react-redux';
const SchoolInsight = (props) => {
  const info_A = {
    month: props.month,
    title: "A Level pass rates ≥ E",
    level: "A Level",
    value: "A Level"
  },
  info_AS = {
    month: props.month,
    title: "AS Level pass rates ≥ E",
    level: "AS Level",
    value: "AS Level"
  },
  info_IGCSE = {
    month: props.month,
    title: "IGCSE pass rates ≥ E",
    level: "IGCSE",
    value: "IGCSE"
  },
  userInfo = useSelector(state=>state.userInfo);
  return (
    <>
      <div className="row">
        <div className="txt d-flex flex-column align-items-start">
          <p className="lbl pr_snap text-start m-0 text-uppercase">{constants.dashboard.header1}</p>
          <p className="mb-3 sub_txt">{props.month}</p>
        </div>
        <div className="row">          
          <div className="col-lg-4 col-md-12 col-sm-12">
            <SchoolInsightItem info={info_AS} exams={props.asLevel}></SchoolInsightItem>
          </div>
          <div className={`col-lg-4 col-md-12 col-sm-12 ${window.innerWidth<992?'mt-2':''}`}>
            <SchoolInsightItem info={info_A} exams={props.aLevel} ></SchoolInsightItem>
          </div>
          <div className={`col-lg-4 col-md-12 col-sm-12 ${window.innerWidth<992?'mt-2':''}`}>
            <SchoolInsightItem info={info_IGCSE} exams={props.iGcse}></SchoolInsightItem>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolInsight;
