import { Button, Card } from "@cambridgeassessment/cambridge-ui";
import { current } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DropDown } from "../../common/Dropdown";
import Spinner from "../../common/Spinner";
import { stringSorting } from "../../common/validations";
import { constants } from "../../constants";
import { setEmlHeadLabels, setSupportMenuBtn, setUserSupport } from "../../redux/slice/newUser";
import { setTabNumber } from "../../redux/slice/sideNavigation";
import { setCentreDataForSupport, setDistrictAndStateIds, setDistrictAndStateNames, setDistrictDataForSupport, setEmlRolesFlag, setStateDataForSupport, setuserInfo } from "../../redux/slice/userInfo";
import { getDistrictList, getSchoolsData, getStateOrDistNames } from "../../services/muleSoftServices";
import './SupportAdmin.css';

const SupportAdmin = () =>{
    const initialState = {
        spin:true,
        stateSelectedValue:{stateCode:"",centreState:""},
        districtSelectedValue:{districtID:"",districtName:""},
        schoolSelectedValue:{centreID:"",centreName:""}
    },    
    [initialData,setInitialData] = useState(initialState),
    userInfo = useSelector((state) => state.userInfo),
    initialized = useRef(false),
    spinnerRef = useRef(""),
    
    dispatch = useDispatch(),
    navigate = useNavigate();

    const selectedOption = (key,li) =>{
        setInitialData((previousState)=>{
            return {...previousState,
                    spin:(key === 'schoolSelectedValue' || isEmpty(li))?false:true,
                    [key]:li};
        })
        if(key === "stateSelectedValue"){
          if(!isEmpty(li)){
            let stateObj = {
              stateId:li.stateCode,
              stateName:li.centreState,
              emlLevel:"state"
            }
            let params = {
              token:userInfo.totalLoggedInData.access_token,
              roleName:userInfo.roleFromSAP,
              stateId:li.stateCode,
              bpId:userInfo.orgs[0]?.bpid
            }                    
            dispatch(setStateDataForSupport({ selectedStateInfo: { stateDetails:stateObj } }));
            getDistOrStateNameWithIds(params);
            getDistrictData(li.stateCode);
          }else{
            const distObj = constants.supportAdmin.dropDowns.filter(s=>s.option === 'districtID');                  
                  distObj[0].dataList = [];
              setInitialData((previousState)=>{
                return {...previousState,
                  districtSelectedValue:{districtID:"",districtName:""},
                  schoolSelectedValue:{centreID:"",centreName:""}}
              })
          }
            const schoolObj = constants.supportAdmin.dropDowns.filter(s=>s.option === 'centreID');                           
                  schoolObj[0].dataList = [];           
        }
        else if(key === 'districtSelectedValue'){
          if(!isEmpty(li)){      
            let districtObj = {
              districtId:li.districtID,
              districtName:li.districtName,
              emlLevel:"district"
            }
            let params = {
              token:userInfo.totalLoggedInData.access_token,
              roleName:userInfo.roleFromSAP,
              districtId:li.districtID,
              bpId:userInfo.orgs[0]?.bpid
            }
            getDistOrStateNameWithIds(params);
            dispatch(setDistrictDataForSupport({ selectedDistrictInfo: { districtDetails:districtObj } }));
            getSchoolsList(li.districtID);
          }else{
            const schoolObj = constants.supportAdmin.dropDowns.filter(s=>s.option === 'centreID');
                  schoolObj[0].dataList = [];
            setInitialData((previousState)=>{
              return {...previousState,schoolSelectedValue:{centreID:"",centreName:""}}
            })            
          }          
        }
        else if(key === "schoolSelectedValue"){                   
          let centreObj = {
            centreId:li.centreID,
            centreName:li.centreName,
            emlLevel:"exams"
          }          
          dispatch(setCentreDataForSupport({ selectedCentreInfo: { centreDetails:centreObj } }));                    
        }
    }

    const getDistOrStateNameWithIds = async(params)=>{
      const names = await getStateOrDistNames(params);
      if(names?.data?.data && names.data.data?.length>0){
        const {centreState,districtName,stateCode,districtID} = names.data.data[0];
        dispatch(setDistrictAndStateNames({stateName:centreState?centreState:"",districtName:districtName?districtName:""}));
        dispatch(setDistrictAndStateIds({state:stateCode?stateCode:"",district:districtID?districtID:""}));
      }
    }

    const getDistrictData = async(stateCode) =>{
        const {totalLoggedInData,roleFromSAP,roleLevel,orgs} = userInfo,
              {districtList} = constants.sideNav.roleBaseApiKeys,
              params = {token:totalLoggedInData.access_token,
                        roleLevel:districtList[roleLevel],
                        roleName:roleFromSAP,
                        stateCode:stateCode,
                        bpId:orgs[0]?.bpid},
                        dList = await getDistrictList(params);
        if(dList?.data?.data){
            const nonEmpty = dList.data.data.filter(r=>r.districtID !== ""),
                  sortedNames = stringSorting(nonEmpty,{ascending:true,key:"districtName"}),
                  distObj = constants.supportAdmin.dropDowns.filter(s=>s.option === 'districtID');
              distObj[0].dataList = sortedNames;
              spinnerRef.current?.spinnerOpen();
              setInitialData((previousState)=>{
                return {...previousState,spin:false,
                        districtSelectedValue:{districtID:"",districtName:""},
                        schoolSelectedValue:{centreID:"",centreName:""}
                }
              })              
        }
    }

    const getSchoolsList = async(districtID) =>{
        const {totalLoggedInData,roleFromSAP,roleLevel,uid,orgs} = userInfo,
              {schoolList} = constants.sideNav.roleBaseApiKeys;            
        const params = {token:totalLoggedInData.access_token,
                      roleName:roleFromSAP,
                      districtId:districtID,
                      bpId:orgs[0]?.bpid,
                      roleLevel:schoolList[roleLevel]},
          schoolData = await  getSchoolsData(params),
          schoolArr = schoolData?.data?.data;
      if(!isEmpty(schoolArr)){
        const schoolObj = constants.supportAdmin.dropDowns.filter(s=>s.option === 'centreID');
          schoolObj[0].dataList = schoolArr;
          spinnerRef.current?.spinnerOpen();
          setInitialData((previousState)=>{
            return {...previousState,spin:false,schoolSelectedValue:{centreID:"",centreName:""}
            }
          })            
      }
    }

    useEffect(()=>{
        const getAllStates = async() =>{
            const {totalLoggedInData,roleFromSAP,orgs} = userInfo,
                  params = {token:totalLoggedInData.access_token,
                            roleName:roleFromSAP,
                            bpId:orgs[0]?.bpid},
                  stateResp = await getStateOrDistNames(params);
            if(stateResp?.data?.data && stateResp.data.data?.length>0){
              const nonEmpty = stateResp.data.data.filter(r=>{  
                                    if(!isEmpty(r.stateCode) && !isEmpty(r.centreState)){  
                                      return r;
                                    } 
                                  }),
                    sortedNames = stringSorting(nonEmpty,{ascending:true,key:"centreState"}),
                    stateObj = constants.supportAdmin.dropDowns.filter(s=>s.option === 'stateCode');           
              stateObj[0].dataList = sortedNames;
              spinnerRef.current?.spinnerOpen();
              setInitialData((previousState)=>{
                return {...previousState,spin:false,stateSelectedValue:{stateCode:"",centreState:""}};
              })
            }
        }
        if(!initialized.current){
          initialized.current = true;
          const distObj = constants.supportAdmin.dropDowns.filter(s=>s.option === 'districtID'),
          schoolObj = constants.supportAdmin.dropDowns.filter(s=>s.option === 'centreID');
          distObj[0].dataList = [];         
          schoolObj[0].dataList = [];
          getAllStates();
        }
    },[])

    const switchToRole = (e) =>{
      dispatch(setSupportMenuBtn({hideMenu:false}));
      dispatch(setTabNumber({reportTabNumber:0,managementTabNumber:-1}));
      if(e.target.innerText.includes("exams")){
        dispatch(setEmlHeadLabels({emlHead:{emlKey:'school',
                                   emlValue:initialData.schoolSelectedValue.centreID+" - "+initialData.schoolSelectedValue.centreName}}));        
        dispatch(setEmlRolesFlag({emlFlags:{centre:true,district:false,state:false}}));
      }else if(e.target.innerText.includes("district")){
        dispatch(setEmlHeadLabels({emlHead:{emlKey:'district',emlValue:initialData.districtSelectedValue.districtName}}));
        dispatch(setEmlRolesFlag({emlFlags:{centre:false,district:true,state:false}}));
      }else if(e.target.innerText.includes("state")){
        dispatch(setEmlHeadLabels({emlHead:{emlKey:'state',emlValue:initialData.stateSelectedValue.centreState}}));
        dispatch(setEmlRolesFlag({emlFlags:{centre:false,district:false,state:true}}));
      }
      dispatch(setUserSupport({support:true}));
      navigate('/dashboard'); 
    }

    useEffect(()=>{
        spinnerRef.current?.spinnerOpen();
    },[initialData.spin])

    return(
        <div>
        {initialData.spin ?<Spinner ref={spinnerRef} />: 
          <div>
            <div className="row mb-4">
              <div className="txt col-lg-8 col-md-12 col-sm-12">
                <div className="h_txt_lbl lbl text-uppercase text-start">{constants.supportAdmin.lbl}</div>
                <div className="sub_text pt-2">{constants.supportAdmin.sub_lbl}</div>
              </div>
            </div>            
                <div className="row">
                {constants.supportAdmin.dropDowns.map((s,i)=>{
                    return(<div className={`col-lg-4 col-md-12 col-sm-12 ${userInfo.smallScreen && i>0?"mt-4":""}`}><Card key={i} className="card_spacing">
                        <label className="mb-4 text-start h_label">{s.header}</label>
                        <DropDown class="text-start" 
                                onChange={selectedOption}
                                selectedValue={initialData[s.userSelectedDropDown][s.option]}
                                userSelectedDropdown={s.userSelectedDropDown}
                                label={""}
                                list={s.dataList}
                                mixedStr={s.strMix}
                                option={s.option} value={s.value} />
                        <Button className="mt-3" onClick={(e)=>switchToRole(e)} disabled={!isEmpty(initialData[s.userSelectedDropDown][s.selectedId])?false:true}>{s.btnLabel}</Button>
                    </Card></div>)})}
                </div>
          </div>}
        </div>
    )
}
export default SupportAdmin;