import {createSlice} from '@reduxjs/toolkit';

export const newUser = createSlice({
    name:'newUser',
    initialState:{new:true,tAndC:"",support:false, emlKey:"", emlValue:"",menuHide:false},
    reducers:{
        setnewUser:(state,action)=>{
            return{...state,new:action.payload.newUser}
        },
        setTAndC:(state,action)=>{
            return{...state,tAndC:action.payload.terms}
        },
        setUserSupport:(state,action)=>{
            return{...state,support:action.payload.support}
        },
        setEmlHeadLabels:(state,action)=>{
            return{...state,
                  emlKey:action.payload.emlHead.emlKey,
                  emlValue:action.payload.emlHead.emlValue  }
        },
        setSupportMenuBtn:(state,action)=>{
            return{...state,menuHide:action.payload.hideMenu}
        }
    }
});

export const {setnewUser,setTAndC,setUserSupport,setEmlHeadLabels,setSupportMenuBtn} = newUser.actions;
export default newUser.reducer;