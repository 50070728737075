import {
  Dropdown,
  DropdownGroup,
  DropdownItem,
  SearchField,
} from "@cambridgeassessment/cambridge-ui";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

export const GrpDropDown = (props) => {
  const {userSelectedDropdown,list,option,onChange,value,
        selectedValue,label} = props,
  dropSelection = (e) =>{
    let extractedList,selectedOption;
    if(userSelectedDropdown === "seriesSelectedValue"){
      extractedList = list.filter(p=>p.tag === "item");
    }
    selectedOption = extractedList.filter(o=>o[option] === e.target?.value)
    onChange(userSelectedDropdown, selectedOption[0]);
  },  
  groupName = (grp) => (
    <DropdownGroup key={grp[option]} label={grp[value]} />
  ),
  itemNames = (item) => (
    <DropdownItem      
      key={item[option]}
      value={item[option]}
      style={{wordBreak:"break-word"}}
    >
      {item[value]}
    </DropdownItem>
  );
  return (
    <Dropdown value={selectedValue} 
              className={props.class} 
              label={label} 
              onChange={dropSelection}>
      {list.map((li) => {
        return li?.tag === "group" ? groupName(li) : itemNames(li);
      })}
    </Dropdown>
  );
};

export const DropDown = (props) => {
  const userInfo = useSelector((state) => state.userInfo),
        {roleLevel,emlCentre,emlDistrict,smallScreen,emlState}= userInfo,
        {list,option,userSelectedDropdown,onChange,value,
        selectedValue,mixedStr,truncate}= props,
  dropSelection = (e) =>{
    let selectedOption = list.filter(o=>o[option] === e.target?.value);    
    onChange(userSelectedDropdown, !isEmpty(selectedOption)?selectedOption[0]:"");
  }

  let dataList = isEmpty(list)?[]:[...list];
  if(smallScreen && roleLevel === "support" && !emlCentre && !emlDistrict && !emlState){
    dataList.unshift({[option]:"Please select",[value]:"Please select..."})
  }else{
    dataList = isEmpty(list)?[]:[...list];
  }

  return (
    <Dropdown value={selectedValue}
              className={props.class} 
              label={props.label}
              onChange={dropSelection}>
      {dataList?.map((li) => {                   
        return (
          <DropdownItem key={li[option]} value={li[option]}>
            {truncate ? <span title={!mixedStr?li[value]:mixedStr ?li[option] +" - "+ li[value]:""} 
                              className="d-inline-block text-truncate" style={{width:"400px",wordBreak:"normal"}}>
                          {!mixedStr?li[value]:mixedStr ?li[option] +" - "+ li[value]:""}
                        </span>:
                        <span style={{wordBreak:"normal"}}>
                          {!mixedStr?li[value]:
                            (mixedStr && li[option].toLowerCase() !== "all syllabuses" && li[option].toLowerCase() !== "please select") ?
                                li[option] +" - "+ li[value]:li[value]}
                        </span>
            }
          </DropdownItem>
        );
      })}
    </Dropdown>
  );
};

export const Search = (props) => (
  <SearchField
    className={props.class}
    label={props.label}
    onChange={props.changedVal}
  />
);
