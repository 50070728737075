import axios from 'axios';
import { isEmpty } from 'lodash';
import {v4 as uuidv4} from 'uuid';

const apiReq = axios.create({
    baseURL:process.env.REACT_APP_MULESOFT_API_ENDPOINT
});

apiReq.interceptors.request.use(
    config => {      
        config.headers['X-TRANSACTION-ID'] = uuidv4();
        config.headers['client_id'] = process.env.REACT_APP_MULESOFT_API_CLIENT_ID;
        config.headers['client_secret'] = process.env.REACT_APP_MULESOFT_API_CLIENT_SECRET;
        return config;
    },
    error =>{ 
        return Promise.reject(error);
    }
)

apiReq.interceptors.response.use(
    response => {
        return response;
    },
    error =>{
        let status = error?.response?.status,
            msg = error?.response?.data?.message;
        if(status === 500 || status === 400 || status === 403 || status === 400){
            return {error:true,message:!isEmpty(msg)?msg:"technical",type:"error"};
            
        }else if(status === 401){
            return {error:true,message:"unauthorized",type:"error"};
        }
    }
)

const schoolsDataUrl = params => `${params.roleLevel}/schools?bpId=${params.bpId}`+ params.reqParams,
      syllabusDataUrl = params => `syllabuses?series=${params.series}&qual=${params.quals}&bpId=${params.bpId}`+ params.reqParams,
      passRateDataUrl = params => `passRateReport/${params.roleLevel}?series=${JSON.stringify(params.series)}&quals=${JSON.stringify(params.quals)}&bpId=${params.bpId}`+ params.reqParams,
      syllabusReportDataUrl = params => `syllabusReport/${params.roleLevel}?series=[${JSON.stringify(params.series)}]&syllabusID=${params.syllabusId}&bpId=${params.bpId}`+ params.reqParams,
      studentReportDataUrl = params => `/studentReport/${params.rLevel}?centreId=${params.centreId}&series=${params.series}&bpId=${params.bpId}`+ params.reqParams,
      demographicDataUrl = params => `demographicReport/${params?.roleLevel}/${params.type}?centreId=${params.centreId}&series=${params?.series}&quals=${params?.quals}&bpId=${params.bpId}&districtId=${params.districtId}&stateId=${params.stateId}`+ params.reqParams,
      studentOrSyllabusDownloadUrl = params => `bulkReports/${params.rLevel}?series=${params.series}&type=${params.type}&userId=${params.uuid}&regenerate=Y&bpId=${params.bpId}`+ params.reqParams,
      templateDownloadUrl = params => `templateDownload?centreId=${params.centreId}&assessmentYear=${params.assessmentYear}&type=${params.type}&bpId=${params.bpId}`+ params.reqParams,
      teacherReportDataUrl = params => `teacherReport/${params.roleLevel}?centreId=${params.centreId}&series=${params.series}&quals=${params.quals}&bpId=${params.bpId}&districtId=${params.districtId}`+ params.reqParams,
      districtOrStateOverviewDataUrl = params => `overview/${params.roleLevel}?series=${params.series}&bpId=${params.bpId}`+ params.reqParams,
      districtOrStateTotalUrl = params => `passRateReport/${params.roleLevel}/total?series=${params.series}&qualId=${params.quals}&bpId=${params.bpId}`+ params.reqParams,
      districtListUrl = params => `${params.roleLevel}/districts?bpId=${params.bpId}`+ params.reqParams,
      candidateHistoryUrl = params => `studentReport/candidate?cambridgeId=${params.cambridgeId}&bpId=${params.bpId}`+ params.reqParams,
      gradeDistributionUrl = params => `gradeDistributionReport/${params.roleLevel}?centreId=${params.centreId}&series=${params.series}&quals=${params.quals}&bpId=${params.bpId}`;

export const getSchoolsData = async (params) =>{        
    if(isEmpty(params.stateId)){
        params.reqParams = `&districtId=${params.districtId}`;
    }else if(isEmpty(params.districtId)){
        params.reqParams = `&stateCode=${params.stateId}`;
    }else{
        params.reqParams = `&stateCode=${params.stateId}&districtId=${params.districtId}`;
    }
    const response = await apiReq({
        url:schoolsDataUrl(params),
        method:'get',
        headers:{
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`,
            'role':params.roleName 
        }        
    })
    return response;
 }

 export const getSyllabusData = async (params) =>{    
    if(!isEmpty(params.centreId)){
        if(params.rLevel === "district"){
            params.reqParams = `&centreId=${params.centreId}&districtId=${params.districtId}`
        }else if(params.rLevel === "state"){
            params.reqParams = `&centreId=${params.centreId}&stateId=${params.stateId}`
        }else{
            params.reqParams = `&centreId=${params.centreId}`;
        }
    }else if(params.rLevel === "district"){
        params.reqParams = `&districtId=${params.districtId}`
    }else if(params.rLevel === "state"){
        params.reqParams = `&stateId=${params.stateId}`
    }else{
        params.reqParams = ``
    }
    const response = await apiReq({
        url:syllabusDataUrl(params),
        method:'get',
        headers:{
           
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`,
            'role':params.roleName
        }      
    })
    return response;
 }

 export const getPassRateData = async (params) =>{    
    if(params.roleLevel === "centre"){
        params.reqParams = `&centreId=${params.centreId}&districtId=${params.districtId}&stateId=${params.stateCode}`;
    }else if(params.roleLevel === "district"){
        params.reqParams = `&districtId=${params.districtId}&stateId=${params.stateCode}`;
    }else if(params.roleLevel === "state"){
        params.reqParams = `&stateId=${params.stateId}&stateCode=${params.stateCode}`;
    }else{
        params.reqParams = ``
    }  
    const response = await apiReq({
        url:passRateDataUrl(params),
        method:'get',
        headers:{
            'Content-Type' :'application/json',
            'Authorization':`Bearer ${params.token}`,
            'role':params.roleName
        }      
    })
    return response;  
}


export const getSyllabusReportsData = async (params) =>{    
    if(params.roleLevel === "teacher"){
       return await getSyllabusReportsDataForTeacher(params);
    }else{        
        if(params.roleLevel === "centre"){
            params.reqParams = `&centreId=${params.centreId}&districtId=${params.districtId}`;
        }else if(params.roleLevel === "district"){
            params.reqParams = `&districtId=${params.districtId}`;
        }else{
            params.reqParams = ``
        }
        const response = await apiReq({
            url:syllabusReportDataUrl(params),
            method:'get',
            headers:{
                'Content-Type' :'application/json',
                'Authorization': `Bearer ${params.token}`,
                'role':params.roleName 
            }       
        })
        return response;
    }
}

export const getSyllabusReportsDataForTeacher = async(params) =>{
    let data = {
        centreId:params.centreId,
        series:params.series,
        syllabusID:params.syllabusId,
        teacherId:params.email,
        bpId:params.bpId,
        districtId:params.districtId,
        stateId:params.stateId
    }
    const response = await apiReq({
        url:`syllabusReport/${params.roleLevel}`,
        method:'post',
        headers:{
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`,
            'role':params.roleName
        },
        data:data
    })
    return response;
}

export const getStudentReportData = async (params) =>{    
    if(params?.candNumber){
        if(params.rLevel === 'district' || params.rLevel === "centre"){
            params.reqParams = `&candidateNumber=${params.candNumber}&districtId=${params.districtId}`;
        }else if(params.rLevel === 'state'){
            params.reqParams = `&candidateNumber=${params.candNumber}&stateId=${params.stateId}`;
        }else{
            params.reqParams = ``;
        }        
    }else if(params?.qualification){
        if(params.rLevel === 'district'){
            params.reqParams = `&qualification=${params.qualification}&districtId=${params.districtId}`;
        }else if(params.rLevel === 'state'){
            params.reqParams = `&qualification=${params.qualification}&stateId=${params.stateId}`;
        }else{
            params.reqParams = ``;
        }        
    }else if((params.rLevel === 'district' || params.rLevel === "centre") && !params?.candNumber && !params?.qualification){
        params.reqParams = `&districtId=${params.districtId}`
    }else{
        params.reqParams = ``;
    }
    const response = await apiReq({
        url:studentReportDataUrl(params),
        method:'get',
        headers:{            
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`,
            'role':params.roleName
        }      
    })
    return response;  
}

export const getdemographicData = async (params,dgType) =>{  
    params.type = dgType;  
    if(params?.syllabusId?.toLowerCase() !== "all syllabuses" && !isEmpty(params?.syllabusId)){
        params.reqParams = `&syllabusCode=${params?.syllabusId}`;
    }else{
        params.reqParams = ``
    }
    const response = await apiReq({
        url:demographicDataUrl(params),
        method:'get',
        headers:{
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params?.token}`,
            'role':params?.roleName  
        }       
    })
    return response;  
}

export const uploadData = async (token,data,role)=>{
    const response = await apiReq({
        url:`/upload`,
        method:'POST',
        headers:{
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${token}`,
            'role':role
        },
       data:data            
    })
    return response; 

}

export const uploadFile = async (token,url,data,role)=>{
    const response = await apiReq({
        url:`${url}`,
        method:'POST',
        headers:{
            'JWT':`Bearer ${token}`, 
            'Content-Type':'multipart/form-data',
            'role':role
        },
       data:data            
    })
    return response;
}
export const uploadFileStatus = async (token,data,role,dataRole)=>{
    let URL;
    if(role === "REPORT_MAKER_EXAMS_OFFICER" || dataRole === "teacher" || dataRole === "student"){
        URL = `uploadStatus/centre?centreId=${data.centreId}&assessmentYear=${data.assessmentYear}&bpId=${data.bpId}&districtId=${data.districtId}`
    }else{
        URL = `uploadStatus/district?districtId=${data.districtId}&assessmentYear=${data.assessmentYear}&bpId=${data.bpId}`
    }
    const response = await apiReq({
        url:URL,
        method:'get',
        headers:{
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${token}`,
            'role':role
        }
            
    })
    return response; 

}

export const downloadStudentOrSyllabus = async (params) =>{
    let centreUrl;    
    if(params.rLevel === "state"){
        params.reqParams = `&stateId=${params.stateId}`;
    }else if(params.rLevel === "district"){
        params.reqParams = `&districtId=${params.districtId}`;
    }else if(params.rLevel === "centre"){
        centreUrl = `bulkReports?centreId=${params.centreId}&series=${params.series}&type=${params.type}&bpId=${params.bpId}`
    }else{
        params.reqParams = ``
    }
    const response  = await apiReq({
        url:params.rLevel === "centre"? centreUrl:studentOrSyllabusDownloadUrl(params),
        method:'get',
        headers:{
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`,
            'role':params.roleName
        }
    })
    return response;
}

export const downloadTemplate = async (params)=>{
    if(params.rLevel === "district"){
        params.reqParams = `&districtId=${params.districtId}`;
    }else{
        params.reqParams = ``;
    }
    const response = await apiReq({
        url:templateDownloadUrl(params),
        method:'GET',
        headers:{
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`, 
            'client_id': 'ec0c2193fcb340f7be773c803a89944d',
            'client_secret': '8e8aB33c10e94f92bdd89d0B8e1fb6bC',
            'role':params.role
        }            
    })
    return response;
}

export const teacherReportData = async (params)=>{    
    if(params?.syllabusId && params?.syllabusId?.toLowerCase() !== "all syllabuses"){
        params.reqParams = `&syllabusID=${params.syllabusId}`;
    }else{
        params.reqParams = ``
    }
    const response = await apiReq({
        url:teacherReportDataUrl(params),
        method:'GET',
        headers:{
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`, 
            'client_id': 'ec0c2193fcb340f7be773c803a89944d',
            'client_secret': '8e8aB33c10e94f92bdd89d0B8e1fb6bC',
            'role':params.roleName
        }
    })
    return response;
}

export const getDistrictOrStateOverviewData = async (params) =>{    
    if(params.roleLevel === "state"){
        params.reqParams = `&stateId=${params.stateId}`;
    }
    else if(params.roleLevel === "district"){
        params.reqParams = `&districtId=${params.districtId}&stateId=${params.stateId}`;
    }else{
        params.reqParams = ``
    }
    const response = await apiReq({        
        url:districtOrStateOverviewDataUrl(params),
        method:'get',
        headers:{            
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`,
            'role':params.roleName
        }      
    })
    return response;  
}

export const getDistrictOrStateTotal = async (params) =>{    
    if(params.roleLevel === "state"){
        params.reqParams = `&state=${params.stateId}`;
    }else if(params.roleLevel === "district"){
        params.reqParams = `&districtId=${params.districtId}&stateId=${params.stateId}`;
    }else{
        params.reqParams = ``
    }
    const response = await apiReq({
        url:districtOrStateTotalUrl(params),
        method:'get',
        headers:{            
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`,
            'role':params.roleName
        }      
    })
    return response;  
}

export const getDistrictList = async (params) =>{    
    if(!isEmpty(params.stateCode)){
        params.reqParams = `&stateCode=${params.stateCode}`;
    }else{
        params.reqParams = ``
    }
    const response = await apiReq({
        url:districtListUrl(params),
        method:'get',
        headers:{            
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`,
            'role':params.roleName
        }      
    })
    return response;  
}

export const getTermsAndConditions = async(params)=>{
    const response = await apiReq({
        url:`termsAndConditions?country=USA&state=${params.stateId}&userId=${params.uid}`,
        method:'get',
        headers:{
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`,
            'role':params.roleName
        }
    })
    return response;
}

export const termsAccepted = async(params)=>{
    const response = await apiReq({
        url:`termsAndConditions`,
        method:'post',
        headers:{
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`,
            'role':params.roleName
        },
        data:params.data
    })
    return response;    
}

export const getDistrictAndStateWithCid = async (params) =>{
    let url;
    if(params?.districtId){
        url = `centre?centreId=${params.centreId}&bpId=${params.bpId}&districtId=${params.districtId}`;
    }else{
        url = `centre?centreId=${params.centreId}&bpId=${params.bpId}`;
    }
    const response  = await apiReq({
        url:url,
        method:'get',
        headers:{
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`,
            'role':params.roleName
        }
    })
    return response;
}

export const getStateOrDistNames = async (params) =>{
    let idUrl;
    if(params.districtId){
        idUrl = `centre/districts?districtId=${params.districtId}&bpId=${params.bpId}`;
    }else if(params.stateId){
        idUrl = `centre/states?stateCode=${params.stateId}&bpId=${params.bpId}`;
    }else if(isEmpty(params.stateId) && isEmpty(params.districtId)){
        idUrl = `centre/states?bpId=${params.bpId}`;
    }
    const response  = await apiReq({
        url:idUrl,
        method:'get',
        headers:{
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`,
            'role':params.roleName
        }
    })
    return response;
}

export const getGradeDistributionList = async(params) =>{
    const response = apiReq({
        url:gradeDistributionUrl(params),
        method:'get',
        headers:{
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`,
            'role':params.roleName
        }
    })
    return response;
}

export const getCandidateHistory = async(params) =>{    
    if(params.roleLevel === "state"){        
        params.reqParams = `&stateCode=${params.stateId}`;
    }else if(params.roleLevel === "district"){        
        params.reqParams = `&districtId=${params.districtId}&stateCode=${params.stateId}`;
    }else{
        params.reqParams = `&centreId=${params.centreId}`;
    }
    const response = apiReq({
        url:candidateHistoryUrl(params),
        method:'get',
        headers:{
            'Content-Type' :'application/json',
            'Authorization': `Bearer ${params.token}`,
            'role':params.roleName
        }
    })
    return response;
}