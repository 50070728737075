import {createSlice} from '@reduxjs/toolkit';

export const demographicData = createSlice({
    name:'demographics',
    initialState:{data:{},rows:[]},
    reducers:{
        setDemographicData:(state,action)=>{
            return{...state,
                    data:action.payload.demographicData,
                    rows:action.payload.demographicData.dataRows}
        }
    }
});

export const {setDemographicData} = demographicData.actions;
export default demographicData.reducer;