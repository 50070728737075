import {createSlice} from '@reduxjs/toolkit';

export const syllabusReport = createSlice({
    name:'syllabusReport',
    initialState:{rows:[]},
    reducers:{
        setSyllabusReport:(state,action)=>{
            return {...state,rows:action.payload.sylReportRows}
        }
    }
})
export const {setSyllabusReport} = syllabusReport.actions;
export default syllabusReport.reducer;