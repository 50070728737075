import {useSelector} from 'react-redux';
import { Alert } from '@mui/material';

export const GlobalMessage = () =>{
    const alert = useSelector(state=>state.globalMessage);    
    return(
        <div>
            <Alert variant='filled' severity={alert.status}>{alert.message}</Alert>
        </div>
    );
}
export default GlobalMessage;