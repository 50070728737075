import { useSelector } from "react-redux";
import { constants } from "../constants";
import './Noaccess.css';

export const NoAccess = () =>{
    const {icon1,icon2,mainText,subText1,subText2,mail} = constants.noAccess,
            userInfo = useSelector(state => state.userInfo);
    return(
        <div className={`${userInfo.smallScreen?"pt-5":"page-center"}`}>
            <div>{icon1}<div className="na"></div>{icon2}</div>
            <h2 className="noAccess_text mt-2">{mainText}</h2>
            <p className={`p-2 ${userInfo.smallScreen?"":"text"}`}>{subText1}</p>
            <p className={`p-2 ${userInfo.smallScreen?"":"text"}`}>{subText2}</p>
            <p><a href={"mailto:"+mail} className='link mt-1'>{mail}</a></p>
            
        </div>
    )
}