import { createSlice } from "@reduxjs/toolkit";

export const userInfo = createSlice({
  name: "userInfo",
  initialState: { name: "", roleFromSAP: '', orgs:[], totalLoggedInData:{}, roleLevel:"", 
                  hierarchy:[], district:"", state:"", districtName:"", stateName:"",
                  emlCentreObj:{}, emlDistrictObj:{}, emlStateObj:{}, uid:"",
                  emlCentre:false, emlDistrict:false, emlState:false, smallScreen:false },
  reducers: {
    setuserInfo: (state, action) => {
      return {
        ...state,
        name: action.payload.userInfo.name,
        roleFromSAP: action.payload.userInfo.roles,
        orgs: action.payload.userInfo.orgs,
        totalLoggedInData:action.payload.userInfo.totalLoggedInData,
        roleLevel:action.payload.userInfo.roleLevel,
        hierarchy:action.payload.userInfo.hierarchy,
        uid:action.payload.userInfo.uid        
      };
    },
    setDistrictAndStateNames:(state,action)=>{
      return{...state,
             districtName:action.payload.districtName,
             stateName:action.payload.stateName};
    },
    setDistrictAndStateIds:(state,action)=>{
      return{...state,
             district:action.payload.district,
             state:action.payload.state};
    },
    setCentreDataForSupport:(state,action)=>{      
        return{...state,
              emlCentreObj:action.payload.selectedCentreInfo.centreDetails} 
    },
    setDistrictDataForSupport:(state,action)=>{      
      return{...state,
            emlDistrictObj:action.payload.selectedDistrictInfo.districtDetails} 
    },
    setStateDataForSupport:(state,action)=>{      
      return{...state,
            emlStateObj:action.payload.selectedStateInfo.stateDetails} 
    },
    setEmlRolesFlag:(state,action)=>{
      return{...state,
             emlCentre:action.payload.emlFlags.centre,
             emlDistrict:action.payload.emlFlags.district,
             emlState:action.payload.emlFlags.state }
    },
    setDeviceInfo:(state,action)=>{
      return{...state,
            smallScreen:action.payload.userInfo.nonDesktop
      }
    }
  },
});

export const { setuserInfo,setDistrictAndStateNames,setCentreDataForSupport,setDistrictAndStateIds,
               setDistrictDataForSupport,setEmlRolesFlag,setStateDataForSupport,setDeviceInfo } = userInfo.actions;
export default userInfo.reducer;
