import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { mainTheme } from "@cambridgeassessment/cambridge-ui";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./redux/store";
import { Provider } from "react-redux";
import { AuthProvider } from "react-oidc-context";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

const oidcConfig = {
  authority: process.env.REACT_APP_SAP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_SAP_OIDC_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_SAP_LOGIN,//Note: for localhost change to https://localhost:3000
  scope: "openid profile email photoURL roles orgs businessStream uid hierarchy",
  response_type: "code",
  automaticSilentRenew: true,
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <AuthProvider {...oidcConfig}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
