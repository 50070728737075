import {createSlice} from '@reduxjs/toolkit';

export const dropDownsData = createSlice({
    name:'dropDown',
    initialState:{schoolData:[],
                  syllabusData:[]},
    reducers:{
        setSchoolsData:(state,action)=>{         
            return{
                ...state,
                schoolData:action.payload.schoolData
            }
        },
        setSyllabusData:(state,action)=>{
            return{
                ...state,
                syllabusData:action.payload.syllabusData
            }
        } 
    }
})

export const {setSchoolsData,
              setSyllabusData} = dropDownsData.actions;
              
export default dropDownsData.reducer;