import {createSlice} from '@reduxjs/toolkit';

export const sideNav = createSlice({
    name:'nav',
    initialState:{reportTab:0,managementTab:-1},
    reducers: {
        setTabNumber:(state,action) =>{
            return{...state,
                   reportTab:action.payload.reportTabNumber,
                   managementTab:action.payload.managementTabNumber}
        }
    }
});

export const {setTabNumber} = sideNav.actions;
export default sideNav.reducer;