import { Button, Card, Link, Notification, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@cambridgeassessment/cambridge-ui";
import { isEmpty } from "lodash";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { DropDown, GrpDropDown } from "../../common/Dropdown";
import Spinner from "../../common/Spinner";
import { getYearFromSeriesSelection } from "../../common/validations";
import { constants } from "../../constants";
import { setSchoolsData } from "../../redux/slice/dropDownsData";
import { setAlert } from "../../redux/slice/GlobalMessage";
import { setTabNumber } from "../../redux/slice/sideNavigation";
import { getDistrictList, getSchoolsData, getStudentReportData } from "../../services/muleSoftServices";
import './AiceReports.css';
import { setAiceReportData } from "../../redux/slice/aiceReports";
import { setRecentSelectedSeries } from "../../redux/slice/passRate";

const AiceReports = () =>{
    const initialState={
        spin:true,
        seriesSelectedValue:{},
        schoolSelectedValue:{},
        districtSelectedValue:{},
        rowsPerPage:25,
        page:0
    },
    [initialData,setInitialData] = useState(initialState),
    {spin,seriesSelectedValue,schoolSelectedValue,
     districtSelectedValue,rowsPerPage,page} = initialData,
    [schoolRes,setSchoolRes] = useState([]),
    [distDropList,setDistDropList] = useState([]),
    userInfo = useSelector(state => state.userInfo),
    {totalLoggedInData,roleFromSAP,roleLevel,state,district,smallScreen,orgs,uid,
     emlCentreObj,emlDistrictObj,emlCentre,emlDistrict,hierarchy,emlState} = userInfo,
    [reportData,setReportData] = useState([]),
    [allReportData,setAllReportData] = useState({}),
    savedPassResp = useSelector(state => state.pass),
    savedAiceData = useSelector(state => state.aiceReport),
    dispatch = useDispatch(),
    location = useLocation(),
    navigate = useNavigate(),
    seriesDropRef = useRef([]),
    spinnerRef = useRef(),
    aiceReportRef = useRef(null),
    yearSubHeaded = useRef(""),
    paginatedRecords = useRef([]),
    selectedSeriesYear = useRef(""),
    initialized = useRef(false),

    getDistrictListForState = async(series) =>{
        const {districtList} = constants.sideNav.roleBaseApiKeys,
              distParams = {token:totalLoggedInData.access_token,
                            roleName:roleFromSAP,
                            roleLevel:districtList[roleLevel],
                            bpId:orgs[0]?.bpid,
                            stateCode:state},
              dList = await getDistrictList(distParams);
        if(dList?.data?.data){
          let district;
          setDistDropList(dList.data.data);
          if(location.state?.detailToReport){
            district = location.state?.district;
          }else{
            district = dList.data.data[0];
          }
          setInitialData((previousState)=>{
            return {...previousState,
                    districtSelectedValue:district}
          })
          retrieveSchools(series,district?.districtID);
        }
    },

    retrieveSchools = async (series,distId) =>{
        let modifiedParams;
        const {schoolList} = constants.sideNav.roleBaseApiKeys;
        if(distId){
            modifiedParams = { districtId:distId }
        }  
        else if(emlCentre){
            modifiedParams = {
                centreId:emlCentreObj.centreId,
                roleName:emlCentreObj.roleName
            }
        }else if(emlDistrict){
            modifiedParams = {
                districtId:emlDistrictObj.districtId,
                roleName:emlDistrictObj.roleName,
                roleLevel:emlDistrictObj.emlLevel
            }
        }

      const params = {token:totalLoggedInData.access_token,
                      roleName:modifiedParams?.roleName?modifiedParams.roleName:roleFromSAP,
                      districtId:modifiedParams?.districtId?modifiedParams.districtId:district,
                      roleLevel:schoolList[roleLevel],
                      bpId:orgs[0]?.bpid,
                      stateId:state?state:!isEmpty(hierarchy) && hierarchy[0]?.stateId},
          schoolData = await getSchoolsData(params),
          schoolArr = schoolData?.data?.data;
        if(!isEmpty(schoolArr)){
            let centre;
            if(location.state?.detailToReport){
                centre = location.state?.centre;
            }else{
                centre = schoolArr[0];
            }
            setSchoolRes(schoolArr);
            setInitialData((previousState)=>{
                return {...previousState,spin:false,schoolSelectedValue:centre}
            })
            dispatch(setSchoolsData({ schoolData:schoolArr}));
            getReportData(series,centre?.centreID)
        }
    }, 

    selectedOption = (key,li) => {
        if((initialData[key]?.label && initialData[key]?.label === li?.label) ||
            schoolSelectedValue?.centreID && schoolSelectedValue?.centreID === li?.centreID ||
            districtSelectedValue?.districtID && districtSelectedValue?.districtID === li?.districtID){
          return;
        }
        if(key === "seriesSelectedValue"){
            dispatch(setRecentSelectedSeries({userSelectedSeries:li}));
            getReportData(li?.label,"");
            selectedSeriesYear.current = getYearFromSeriesSelection(li?.label);            
        }else if(key === "schoolSelectedValue"){
            getReportData(seriesSelectedValue?.label,li?.centreID);
        }else if(key === "districtSelectedValue"){
            retrieveSchools(seriesSelectedValue?.label,li?.districtID);
        }
        setInitialData(previousState=>{
            return {...previousState,spin:true,[key]:li}
        });    
    },

    handleViewRecord = (currentStudent,recs,index) => {       
        if(currentStudent.candidateNumber !== recs[index].candidateNumber){
            recs = reportData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        }      
        const studentInfo = {
          currentStudent:currentStudent,reDirectTo:"/aice-reports",tab:2,
          centName:schoolSelectedValue.centreName,
          onPageRecs:recs,
          recIndex:index,
          selectedParams:{series:seriesSelectedValue,
                          centre:schoolSelectedValue,
                          district:districtSelectedValue,
                          detailToReport:true},
          series:seriesSelectedValue?.label?seriesSelectedValue.label:reportData.series,
          centreId:schoolSelectedValue?.centreID?schoolSelectedValue.centreID:allReportData.centreID,
          districtId:districtSelectedValue?.districtID ? districtSelectedValue.districtID:district,
          stateId:state?state:!isEmpty(hierarchy) && hierarchy[0]?.stateId
        }; 
        if(roleLevel !== "state"){
            dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:4}));
        }
        dispatch(setAiceReportData({aiceData:reportData,totalAiceData:allReportData}));
        navigate("/student-details",{state:studentInfo});
    }, 

    downloadPdf = useReactToPrint({
        bodyClass:"m-2 p-2",
        documentTitle:'aice_report',
        content: () => aiceReportRef.current
    }),

    pageChange = (event,newPage) => {
        paginatedRecords.current = reportData.slice((page+1) * rowsPerPage, (page+1) * rowsPerPage + rowsPerPage);
        setInitialData(previousState =>{
            return {...previousState,page:newPage};
        })
    },
    
    handleChangeRowsPerPage = (event) => {        
        paginatedRecords.current = reportData.slice(0 * event.target.value, 0 * event.target.value + event.target.value);
        setInitialData(previousState =>{
            return {...previousState,rowsPerPage:+event.target.value,page:0};
        })       
    },
    
    getReportData = async (series,cId) =>{
        let sortedRecs = [];
        if(location.state?.detailToReport){
            setReportData(savedAiceData.reportData);
            setAllReportData(savedAiceData.totalReportData);
            sortedRecs = savedAiceData.reportData;
        }else{
            let modifiedParams;            
            const {studentReport} = constants.sideNav.roleBaseApiKeys;
            if(!isEmpty(cId)){
                modifiedParams = { centreId:cId }
            }else if(emlCentre){
                modifiedParams = {
                centreId:emlCentreObj.centreId,
                roleName:emlCentreObj.roleName
                }
            }else if(emlDistrict){
                modifiedParams = {
                districtId:emlDistrictObj.districtId,
                roleName:emlDistrictObj.roleName,
                roleLevel:emlDistrictObj.emlLevel
                }
            } 
            const params = {token:totalLoggedInData.access_token,
                            roleName:modifiedParams?.roleName?modifiedParams.roleName:roleFromSAP,
                            centreId:modifiedParams?.centreId?modifiedParams.centreId:
                                    schoolSelectedValue?.centreID?schoolSelectedValue.centreID:
                                    orgs[0]?.sourceSystems[0]?.orgId,                                            
                            series:series,
                            roleLevel:studentReport[roleLevel],
                            qualification:'ADIP',
                            bpId:orgs[0]?.bpid,
                            rLevel:roleLevel,
                            stateId:!isEmpty(userInfo.state)?userInfo.state:!isEmpty(userInfo.hierarchy) && userInfo.hierarchy[0]?.stateId,
                            districtId:modifiedParams?.districtId?modifiedParams.districtId:district},           
            stuReport = await getStudentReportData(params);
            if(stuReport.error){
                setReportData([]);
            }else{
                const stuReportCpy = [...stuReport.data.dataRows],
                    nonEmptyRecs = stuReportCpy.filter(x=>x.number !== "");
                sortedRecs = nonEmptyRecs.sort((a,b)=>{ return a.candidateNumber - b.candidateNumber});                
                setReportData(sortedRecs);
                setAllReportData(stuReport.data);                
            }
        }
        paginatedRecords.current = sortedRecs?sortedRecs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage):[];
        if(location.state?.detailToReport){
            location.state = {};
        }
        yearSubHeaded.current = constants.aiceReports.subHead4.replace("selectedYear", selectedSeriesYear.current);
        spinnerRef.current?.spinnerOpen();
        setInitialData(previousState =>{
            return {...previousState,spin:false}
        })
    };

    useEffect(()=>{
      spinnerRef.current?.spinnerOpen();
    },[spin])
    
    useEffect(()=>{
        window.scrollTo(0,0);
        let firstSeries;
        if(!initialized.current){                   
            if(!isEmpty(savedPassResp.seriesDropList)){                
                seriesDropRef.current = savedPassResp.seriesDropList;
                if(location.state?.detailToReport){                    
                    firstSeries = [location.state.series];
                }else{
                    firstSeries = savedPassResp.seriesDropList?.filter(s=>s.label === savedPassResp.seriesLabels[0]);
                }
                setInitialData(previousState =>{
                    return {...previousState,
                            seriesSelectedValue:firstSeries[0],
                            schoolSelectedValue:location.state?.centre ? location.state.centre:schoolSelectedValue ,
                            districtSelectedValue:location.state?.district ? location.state.district:districtSelectedValue}
                })                
            }
            
            if(roleLevel === "state" || emlState){
                getDistrictListForState(firstSeries[0].label);
            }else if(roleLevel === "district" || emlDistrict){
                retrieveSchools(firstSeries[0].label,"");
            }else if(roleLevel === "exams" || emlCentre || roleLevel === "head"){
                getReportData(firstSeries[0].label)
            }
            selectedSeriesYear.current = getYearFromSeriesSelection(firstSeries[0].label);
            initialized.current = true;            
        }
    },[])

    return(
        <div ref={aiceReportRef}> 
            {spin ?<Spinner ref={spinnerRef}/>:
            <> 
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12 txt">
                        <div><label className="lbl">{constants.aiceReports.head1}</label></div>                                                
                    </div>
                    <div className={`col-lg-4 col-md-12 col-sm-12 mt-3 ${smallScreen?"text-start":"text-end"}`}>
                        <Button className={`${smallScreen?"float-start mb-2":"float-end"}`} 
                                onClick={() => {dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:0}));navigate("/dashboard");}}>
                            Download school data files
                        </Button>
                    </div>
                </div>
                <div className="sub_text teacherRep_sub_text mt-2">{constants.aiceReports.subHead1}{selectedSeriesYear.current}</div>    
                <div className='row mb-4'>
                {(roleLevel === 'state' || emlState) &&
                    <div className={`col-lg-4 col-md-12 col-sm-12 ${smallScreen?"mb-3":""}`}>
                        <DropDown class="text-start"
                            label='DISTRICT'
                            userSelectedDropdown='districtSelectedValue'
                            selectedValue={districtSelectedValue?.districtID}
                            list={distDropList?.length?distDropList:[]}
                            onChange={selectedOption}
                            option='districtID' value='districtName'/>
                    </div>}
                    {(roleLevel === 'state' || emlState || 
                      roleLevel === 'district' || emlDistrict) &&
                    <div className={`col-lg-4 col-md-12 col-sm-12 ${smallScreen?"mb-3":""}`}>
                        <DropDown class="text-start"
                                label='SCHOOL'
                                userSelectedDropdown='schoolSelectedValue'
                                selectedValue={schoolSelectedValue?.centreID}
                                list={schoolRes?.length?schoolRes:[]}
                                onChange={selectedOption}
                                option='centreID' value='centreName'
                                mixedStr={true}/>
                    </div>}
                    <div className="col-lg-3 col-md-12 col-sm-12">
                        <GrpDropDown class="text-start" 
                                    onChange={selectedOption}
                                    selectedValue={seriesSelectedValue?.label}
                                    userSelectedDropdown='seriesSelectedValue'
                                    label={constants.passRate.dropDownLabels.series} 
                                    list={seriesDropRef.current}
                                    option='label' value='label' />
                    </div>                                                         
                </div>
                {reportData?.length>0 && <Card className="card_spacing">
                <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <h3 className="card-header text-start mb-2">{constants.aiceReports.head2}</h3>
                  <p className="mb-4 text-start card-sub-head lh-base">{yearSubHeaded.current}{" "}{constants.aiceReports.subHead2}{" "}
                    <a className="link" target="_blank" href={constants.aiceReports.aiceDiplomaLink}>{constants.aiceReports.subHead3}</a>
                  </p>
                </div>
                <div className={`col-lg-4 col-md-12 col-sm-12 ${smallScreen?'text-start mb-3':' text-end'}`}>
                  <Button variant="outlined" startIcon={<svg  fill="#0076BD" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                    <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>} onClick={downloadPdf}>
                      Download (pdf)
                  </Button>
                </div>
              </div>         
                <TableContainer>
                    <Table aria-label="student report table" style={{width:smallScreen?"100dvw":"100%"}}>
                        <TableHead>                    
                            <TableRow className='h_bottom'>
                                {constants.aiceReports.tableHeaders.map((h,i)=>                                
                                    {return(<TableCell key={i} className={h.class}>{h.label}</TableCell>)})
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                {reportData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((r,i)=>{
                                    if(!r.gradeName){
                                        r.components.map((c,j)=>{
                                            let gLevel = constants.aiceReports.grades[c?.grade?.toLowerCase()];
                                            r.gradeName = ((c.grade || isEmpty(c.grade)) && isEmpty(gLevel)) ? "-": gLevel;
                                        })
                                    }
                                    return(<TableRow hover={true} key={i}>
                                             <TableCell>{r.candidateNumber}</TableCell>
                                             <TableCell 
                                                title={r.studentName} 
                                                className="text-truncate stu_col_width">
                                                {/* <Link onClick={()=>handleViewRecord(r,paginatedRecords.current,i)} className="studentNameCls"> */}
                                                        {r.studentName}
                                                {/* </Link> */}
                                             </TableCell>                                             
                                             <TableCell>{r.gradeName?r.gradeName:"-"}</TableCell>
                                           </TableRow>)})}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination component='div' 
                                count={reportData.length} 
                                page={page} 
                                rowsPerPage={rowsPerPage} 
                                rowsPerPageOptions={[25,50,100,250]} 
                                onPageChange={pageChange}
                                onRowsPerPageChange={handleChangeRowsPerPage} />                          
                </Card>}
                {!reportData?.length && (roleLevel === "exams" || roleLevel === "head" || emlCentre) &&
                 <Notification variant="info" className="mt-4">
                    Data not available for {seriesSelectedValue.label}
                 </Notification>}
                {!reportData?.length && (roleLevel === "state" || roleLevel === "district" || 
                                        emlDistrict || emlState) &&
                 <Notification variant="info" className="mt-4">
                    Data not available for {seriesSelectedValue.label} for the selected options
                 </Notification>} 
            </>
            }
        </div>
        );
}
export default AiceReports;