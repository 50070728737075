export const stringSorting = ([...arr],sort) =>{
    if(sort.ascending){
        const sortedArr = arr.sort((a,b)=>{
            const strOne =  a[sort.key]?.toLowerCase(),
                strTwo =  b[sort.key]?.toLowerCase();
            if(strOne<strTwo){
                return -1;
            }else if(strOne>strTwo){
                return 1;
            }else{
                return 0;
            }
        });
        return sortedArr;
    }else{
        return arr.reverse();
    }
}

export const numberSorting = ([...arr],sort) =>{
    if(sort.ascending){
        const sortedArr = arr.sort((a,b)=>{
            return a[sort.key]-b[sort.key];
        });
        return sortedArr;
    }else{
        return arr.reverse();
    }
}

export const showPendingNotification = (series) =>{
    const yearArr = series?.match(/\d/g),        
          selectedYear = yearArr?yearArr.join(""):"",
          selectedMonth = series?.replace(/[^a-zA-Z]+/g, ''),
          currentDate = new Date();
    let comparisonDate = new Date(Date.parse(selectedMonth +`15 ${selectedYear}`)),status;          
    comparisonDate.setMonth(comparisonDate.getMonth()+2);          
    status = currentDate < comparisonDate ? "pending" : "no data";
    return status;
}

export const getAssessmentYear = () =>{
    let date = new Date(),year,
    currentMonthNum  = date.getMonth()+1;
    if(currentMonthNum<3){
      year = (date.getFullYear()-1).toString();
    }else{
      year = date.getFullYear().toString();
    }
  return year;
}

export const getYearFromSeriesSelection = (series) =>{
    let num = series?.match(/\d/g),
        numStr = num?num.join(""):series;
    return numStr;
}