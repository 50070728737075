const MESSAGES = {
  CHECKING_FOR_VIRUSES:'Checking for Viruses',
  UPLOADING:'Uploading',
  PROCESSING_FILE:{
    TITLE:"Processing file",
    CONTENT:"This file is currently being processed, this usually takes up to 1 hour. Please return shortly."
  },
  INFECTED_FILE:{
    TITLE:"Infections detected",
    CONTENT:"Uploaded File Teacher Roster/Student Demographic file is INFECTED and deleted. Please upload the clean file again."
  },
  DATA_FILE_VALIDATION:{
    SUCCESS:{
    TITLE:"Data file uploaded and processed",
    CONTENT:"success"
    },
    ERROR:{
      TITLE:"Errors found. All valid data has been saved.",
      CONTENT:"error"
    }
  },
  UPLOAD_FAIL:{
    ERROR:{
      TITLE:"File upload failed",
      CONTENT:"Facing some technical issue while uploading"
    }
  }
};
export default MESSAGES;
