import {createSlice} from '@reduxjs/toolkit';

export const notificationWebSocket = createSlice({
    name:'notificationWebSocket',
    initialState:{websocketInstance:null,notificationMsg:""},
    reducers:{
        setWebSocket:(state,action)=>{
            return{...state,websocketInstance:action.payload.notificationWebSocket.websocketInstance,
                notificationMsg:action.payload.notificationWebSocket.notificationMsg}
        }
    }
});

export const {setWebSocket} = notificationWebSocket.actions;
export default notificationWebSocket.reducer;