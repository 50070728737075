import { configureStore } from "@reduxjs/toolkit";
import sideNav from "./slice/sideNavigation";
import passRate from "./slice/passRate";
import newUser from "./slice/newUser";
import studentReport from "./slice/studentReport";
import syllabusReport from "./slice/syllabusReport";
import demographics from "./slice/demographics";
import userInfo from "./slice/userInfo";
import dropDownsData from "./slice/dropDownsData";
import GlobalMessage from "./slice/GlobalMessage";
import notificationWebSocket from "./slice/notificationWebSocket";
import aiceReports from "./slice/aiceReports";

export default configureStore({
  reducer: {
    nav: sideNav,
    pass: passRate,
    new: newUser,
    stuReport: studentReport,
    sylReport: syllabusReport,
    demographic: demographics,
    userInfo: userInfo,
    dropDowns: dropDownsData,
    globalMessage:GlobalMessage,
    aiceReport:aiceReports,
    notificationWebSocket:notificationWebSocket
  },
});
