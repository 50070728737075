import {createSlice} from '@reduxjs/toolkit';

export const aiceReportData = createSlice({
    name:'aiceReports',
    initialState:{reportData:[],totalReportData:{}},
    reducers:{
        setAiceReportData:(state,action)=>{
            return{...state,
                    reportData:action.payload.aiceData,
                    totalReportData:action.payload.totalAiceData}

        }
    }
});

export const {setAiceReportData} = aiceReportData.actions;
export default aiceReportData.reducer;