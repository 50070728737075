import { Button, CircularProgress, Radio } from "@cambridgeassessment/cambridge-ui";
import { isEmpty } from "lodash";
import { useRef, useState } from "react";
import  { Modal } from 'react-bootstrap';
import './Modal.css';

export const DownloadModal = ({open,close}) =>{
    return(
        <Modal show={open} onHide={close} centered size="md">
            <Modal.Header className="border-0" closeButton></Modal.Header>
            <Modal.Body>
                <div className="text-sm-center"><h4><span className="fw">We are generating your report</span></h4></div>
                <div className="mt-4 text-sm-center"><h6><span className="fw">This could take a few minutes.</span></h6></div>
                <div className="mt-4 text-sm-center"><h6 className="fs">You can close this message and your report will</h6></div>
                <div className="text-sm-center"><h6 className="fs">download automatically.</h6></div>
                <div className="mt-4 text-sm-center"><CircularProgress/></div>
            </Modal.Body>
        </Modal>
    )
}

export const CentreSelectionModal = ({open,close,orgs}) =>{
    const [selectedCentre,setSelectedCentre] = useState({}),
          [orgId,setOrgId] = useState("") ;
    return(
        <Modal show={open} centered scrollable={true} size="lg">
            <Modal.Body>
                <p className="mb-2 text-start fw-semibold">Select your centre</p>
                <p className="text-start fs"><strong>Because you're associated with multiple centres you need to select one first. </strong>
                Later you will be able to switch between centres by clicking on the Select another centre link on the home page.</p>
                <div style={{maxHeight:"170px",overflowY:"auto"}}>
                {orgs.map((c,i)=>{
                return(    
                      <div key={i} style={{marginLeft:"2px"}}>
                        <Radio fs 
                               name="radio" 
                               checked={c?.sourceSystems[0]?.orgId === orgId}
                               label={`${c?.sourceSystems[0]?.orgId}` +" - "+ `${c?.name}`} 
                               onChange={() => {
                                 setSelectedCentre(orgs[i]);
                                 setOrgId(c?.sourceSystems[0]?.orgId);
                                }} />
                      </div>                     
                )})}
                
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isEmpty(selectedCentre)} onClick={()=> close([selectedCentre])}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    )
}